import Vue from 'vue'
import Vuex from 'vuex'
import {ConfigModel} from "../model/ConfigModel";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // 网站配置
        webConfig: {},
        // 系统配置
        systemConfig: {},
        // 介绍配置
        introduceConfig: {},
        // 是否是登录页
        isLoginPage: false,
        // 用户信息
        userInfo: {},
        // 选择的学院id 0代表学校
        collegeId: "0",
        // 导航学院列表
        navCollegeList: [],
        // 选择的介绍id
        introduceId: "",
        // 入口课程id
        courseId:"",
        // 入口课程信息
        courseInfo:{},
        // 登录方式
        loginFrom:"us"
    },
    mutations: {
        SET_WEB_CONFIG: (state, webConfig) => {
            state.webConfig = webConfig
        },
        SET_SYSTEM_CONFIG: (state, systemConfig) => {
            state.systemConfig = systemConfig
        },
        SET_INTRODUCE_CONFIG: (state, introduceConfig) => {
            state.introduceConfig = introduceConfig
        },
        SET_IS_LOGIN_PAGE: (state, result) => {
            state.isLoginPage = result
        },
        SET_USERINFO: (state, userInfo) => {
            state.userInfo = userInfo
        },
        SET_COLLEGE_ID: (state, collegeId) => {
            state.collegeId = collegeId
        },
        SET_NAV_COLLEGE_LIST: (state, navCollegeList) => {
            state.navCollegeList = navCollegeList
        },
        SET_COURSE_ID: (state, courseId) => {
            state.courseId = courseId
        },
        SET_COURSE_INFO: (state, courseInfo) => {
            state.courseInfo = courseInfo
        },
        SET_LOGIN_FROM: (state, loginFrom) => {
            state.loginFrom = loginFrom
        },
    },
    actions: {
        // 获取网站配置
        async getWebConfig({commit, state}) {
            let webConfig = await ConfigModel.getConfig("webConfig");
            webConfig = JSON.parse(webConfig)
            commit("SET_WEB_CONFIG", webConfig)
        },
        // 获取系统配置
        async getSystemConfig({commit, state}) {
            let systemConfig = await ConfigModel.getConfig("systemConfig");
            systemConfig = JSON.parse(systemConfig)
            commit("SET_SYSTEM_CONFIG", systemConfig)
            return systemConfig
        },
        // 获取介绍配置
        async getIntroduceConfig({commit, state}) {
            let introduceConfig = await ConfigModel.getConfig("introduceConfig");
            introduceConfig = JSON.parse(introduceConfig)
            commit("SET_INTRODUCE_CONFIG", introduceConfig)
        },
        // 设置是否是登录页
        setIsLoginPage({commit, state}, result) {
            commit("SET_IS_LOGIN_PAGE", result)
        },
        // 设置用户信息
        setUserInfo({commit, state}, userInfo) {
            commit("SET_USERINFO", userInfo)
        },
        // 设置选择学院id
        setCollegeId({commit, state}, collegeId) {
            commit("SET_COLLEGE_ID", collegeId)
        },
        // 设置导航学院列表
        setNavCollegeList({commit, state}, navCollegeList) {
            commit("SET_NAV_COLLEGE_LIST", navCollegeList)
        },
        // 设置课程id
        setCourseId({commit, state}, courseId) {
            commit("SET_COURSE_ID", courseId)
        },
        // 设置课程信息
        setCourseInfo({commit, state}, courseInfo) {
            commit("SET_COURSE_INFO", courseInfo)
        },
        // 设置登录来源
        setLoginFrom({commit, state}, loginFrom) {
            commit("SET_LOGIN_FROM", loginFrom)
        },
    },
    modules: {}
})
