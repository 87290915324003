import {getConfig} from "@/api/ConfigApi";
import {CONFIG_NAME} from "@/config/main";

/**
 * 配置model
 */
class ConfigModel {
  // 获取配置
  static async getConfig(field) {
    let [data] = await getConfig(CONFIG_NAME, field);
    return data.data;
  }

}

export {ConfigModel}
