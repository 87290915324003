import {request_async} from "@/utils/requestAsync";
import {API_URL} from "@/config/main";

// 登录平台
export async function loginInPlatform(data) {
    return request_async(API_URL + "/v1/user/loginInPlatform", "get", data);
}

// 专家用户直接登录平台
export async function loginInPlatformForExpert(data) {
    return request_async(API_URL + "/v1/user/loginInPlatformForExpert", "get", data);
}

// demo用户直接登录平台
export async function loginInPlatformForDemo(data) {
    return request_async(API_URL + "/v1/user/loginInPlatformForDemo", "get", data);
}

// 通过token获取用户信息
export async function getInfoByToken(data) {
    return request_async(API_URL + "/v1/user/infoByToken", "get", data);
}

// 首次登录补充用户信息
export async function supplementUserInfoOnFirstLogin(data) {
    return request_async(API_URL + "/v1/user/supplementUserInfoOnFirstLogin", "post_body", data);
}

// 检测和发送重置密码邮件
export async function forgetPasswordSendEmail(data) {
    return request_async(API_URL + "/v1/user/forgetPasswordSendEmail", "get", data);
}

// 发送绑定邮箱验证码
export async function sendBindEmail(data) {
    return request_async(API_URL + "/v1/user/sendBindEmail", "get", data);
}

// 发送账号注册邮箱验证码
export async function sendRegEmail(data) {
    return request_async(API_URL + "/v1/user/sendRegEmail", "get", data);
}

// 判断用户信息是否重复
export async function checkUserInfoRepeat(type, value) {
    return request_async(API_URL + `/v1/user/checkUserInfoRepeat?type=${type}&value=${value}`, "get", {});
}

// 用户修改密码
export async function changePassword(data) {
    return request_async(API_URL + "/v1/user/changePassword", "get", data);
}

// 用户修改邮箱
export async function changeEmail(data) {
    return request_async(API_URL + "/v1/user/changeEmail", "get", data);
}

// 校外人员注册功能
export async function regAccount(data) {
    return request_async(API_URL + "/v1/user/regAccount", "post_json", data);
}
