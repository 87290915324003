// api地址
// const API_URL = "http://192.168.70.70:9889/"; // 本地
const API_URL = "https://expLab.xhyjbj.com/api/"; // 线上正式

const PLATFORM_ID = "shenBao"
const CONFIG_NAME = "config_shenBao"

export {
    API_URL,
    PLATFORM_ID,
    CONFIG_NAME
}
