import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// 避免跳转同路由时报错 https://www.caorui.net/blog/147173074460999680.html
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [
    {
        // 评价列表页
        path: '/login',
        name: "Login",
        component: () => import('@/views/login')
    },
    {
        // 课程列表页
        path: '/courseList',
        name: "CourseList",
        component: () => import('@/views/courseList')
    },
    {
        // 介绍页
        path: '/introduce',
        name: "Introduce",
        component: () => import('@/views/introduce')
    },
    {
        // 个人中心
        path: '/user/',
        name: "UserLayout",
        component: () => import('@/views/user/layout'),
        children: [
            {
                // 我的信息
                path: 'info',
                name: "UserInfo",
                component: () => import('@/views/user/info')
            },
            {
                // 我的课程
                path: 'course',
                name: "UserCourse",
                component: () => import('@/views/user/course')
            }
        ]
    },
    {
        // 课程项目首页
        path: '/:coursePath',
        name: "CourseInfo",
        component: () => import('@/views/courseInfo')
    },
    {
        // 网页实验跳转-中共一大demo
        path: '/enter/zgyd',
        name: "zgyd_demo",
        component: () => import('@/views/enter/zgydDemo')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes
})

export default router
