/**
 * 路由钩子
 */

import router from './index'
import store from '../store'
import {UserModel} from "../model/UserModel";


router.beforeEach(async (to, from, next) => {
    /**
     * 每次刷新页面后的初始化
     */
    // 如果是登录页或者网页跳转页
    if (["/login"].indexOf(to.path) === 0 || to.path.indexOf("/enter") > -1) {
        // 标记在登录页
        await store.dispatch("setIsLoginPage", true)
        // 判断是否已经登录
        // if (UserModel.getToken()) {// 如果已经登录
        //     window.location.href = "/"
        // }
    } else {
        await store.dispatch("setIsLoginPage", false)
        // 判断是否已经登录
        if (UserModel.getToken()) {
            if (!store.state.userInfo.hasOwnProperty("userId")) {// 如果没有获取过用户信息
                // 通过token获取用户信息并记录到store
                await UserModel.getInfoByToken()
            }
            // if(store.state.userInfo.hasLogin===false){// 如果没有设置初始信息
            //     UserModel.loginOut()
            // }
        } else {// 如果没有登录
            // window.location.href = "/login"
        }
        // 获取缓存的课程信息
        if (sessionStorage.getItem("courseInfo")) {
            let courseInfo = JSON.parse(sessionStorage.getItem("courseInfo"))
            // 保存课程信息到store
            await store.dispatch("setCourseInfo", courseInfo)
            await store.dispatch("setCourseId", courseInfo.courseId)
        }
        // 判断登录方式
        if (sessionStorage.getItem("iLabInfo")) {
            await store.dispatch("setLoginFrom", "iLab")
        }
    }
    next()
})
