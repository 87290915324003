<template>
  <div id="app">
    <!--页首-->
    <page-header v-if="!isLoginPage"></page-header>
    <!--页中-->
    <router-view/>
    <!--页尾-->
    <page-footer v-if="!isLoginPage"></page-footer>
  </div>
</template>
<script>
import pageHeader from "./views/components/pageHeader";
import pageFooter from "./views/components/pageFooter";
import {mapState} from "vuex";

export default {
  components: {pageHeader, pageFooter},
  computed: {
    ...mapState({
      webConfig: state => state.webConfig,
      isLoginPage: state => state.isLoginPage
    })
  },
}
</script>

<style lang="less">
</style>
