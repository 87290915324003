import {
    changeEmail,
    changePassword, checkUserInfoRepeat,
    forgetPasswordSendEmail,
    getInfoByToken,
    loginInPlatform, loginInPlatformForDemo, loginInPlatformForExpert, regAccount, sendBindEmail, sendRegEmail,
    supplementUserInfoOnFirstLogin
} from "../api/UserApi";
import Cookies from 'js-cookie'
import store from '../store'
import {msg_success} from "../utils/ele_component";

const TokenKey = 'Shenbao-User-Token'

class UserModel {
    // 专家登录平台
    static async loginInPlatformForExpert() {
        let [res] = await loginInPlatformForExpert({})
        if (res.code === "000000") {
            let result = res.data
            // 保存token到cookie中
            let token = result["token"]
            this.setToken(token)
            // 保存用户信息到store中
            let userInfo = result["userInfo"]
            await store.dispatch("setUserInfo", userInfo)
            return res.data
        } else {
            return false
        }
    }

    // demo用户登录平台
    static async loginInPlatformForDemo() {
        let [res] = await loginInPlatformForDemo({})
        if (res.code === "000000") {
            let result = res.data
            // 保存token到cookie中
            let token = result["token"]
            this.setToken(token)
            // 保存用户信息到store中
            let userInfo = result["userInfo"]
            await store.dispatch("setUserInfo", userInfo)
            return res.data
        } else {
            return false
        }
    }

    // 登录平台
    static async loginInPlatform(account, password) {
        let [res] = await loginInPlatform({
            account,
            password
        })
        if (res.code === "000000") {
            let result = res.data
            // 保存token到cookie中
            let token = result["token"]
            this.setToken(token)
            // 保存用户信息到store中
            let userInfo = result["userInfo"]
            await store.dispatch("setUserInfo", userInfo)
            return res.data
        } else {
            return false
        }
    }

    // 通过token获取用户信息
    static async getInfoByToken() {
        let [res] = await getInfoByToken({})
        if (res.code === "000000") {
            // 保存用户信息到store中
            let userInfo = res.data
            await store.dispatch("setUserInfo", userInfo)
            return res.data
        } else {
            return false
        }
    }

    // 首次登录补充用户信息
    static async supplementUserInfoOnFirstLogin(password, email, code) {
        let [res] = await supplementUserInfoOnFirstLogin({
            password,
            email,
            code
        })
        return res.data === true;
    }

    // 检测和发送重置密码邮件
    static async forgetPasswordSendEmail(account) {
        let [res] = await forgetPasswordSendEmail({
            account
        })
        if (res.code === "000000") {
            let result = res.data
            if (result) {
                msg_success(`您的重置密码邮件已发送到${result}中，请按邮件提示进行操作`)
                return true
            } else {
                msg_success("重置密码邮件发送失败！")
                return false
            }
        }
    }

    // 发送绑定邮箱验证码
    static async sendBindEmail(email) {
        let [res] = await sendBindEmail({
            email
        })
        if (res.code === "000000") {
            let result = res.data
            if (result) {
                msg_success(`验证码已发送到${result}中，请输入邮箱中的6位数字验证码！`)
                return true
            } else {
                msg_success("邮件发送失败！")
                return false
            }
        }
    }

    // 发送注册邮箱验证码
    static async sendRegEmail(email) {
        let [res] = await sendRegEmail({
            email
        })
        if (res.code === "000000") {
            let result = res.data
            if (result) {
                msg_success(`验证码已发送到${result}中，请输入邮箱中的6位数字验证码！`)
                return true
            } else {
                msg_success("邮件发送失败！")
                return false
            }
        }
    }

    // 判断用户信息是否重复
    static async checkUserInfoRepeat(type, value) {
        let [res] = await checkUserInfoRepeat(
            type,
            value
        )
        if (res.code === "000000") {
            return res.data;
        }
    }

    // 用户修改密码
    static async changePassword(oldPassword, newPassword) {
        let [res] = await changePassword({
            oldPassword,
            newPassword
        })
        return res.code === "000000"
    }

    // 用户修改邮箱
    static async changeEmail(email, code) {
        let [res] = await changeEmail({
            email, code
        })
        return res.code === "000000"
    }

    // 退出登录
    static loginOut() {
        this.removeToken()
        let coursePath = sessionStorage.getItem("coursePath")
        window.location.href = "/" + coursePath
    }

    static getToken() {
        return sessionStorage.getItem(TokenKey)
    }

    static setToken(token) {
        return sessionStorage.setItem(TokenKey, token)
    }

    static removeToken() {
        return sessionStorage.removeItem(TokenKey)
    }

    // 校外人员注册
    static async regAccount(regInfo) {
        let [res] = await regAccount(regInfo)
        if (res.code === "000000") {
            return res.data
        }
    }
}

export {UserModel}
