<template>
  <!--页面顶部-->
  <div class="page-header">
    <div class="content-container flex flex-between">
      <div class="left flex flex-start">
        <div class="web-title flex flex-start">
          <img :src="courseInfo.headAvatar" alt="" class="logo">
          <span class="title">{{ courseInfo.name }}</span>
        </div>
        <!--导航列表-->
        <div class="menu-list flex flex-start">
          <div :class="navIndex==='2-'+item.id?'li active':'li'" v-for="(item,index) in courseInfo.headerTabs"
               v-if="item.showNav"
               @click="selectNav('2-'+item.id)">{{ item.name }}
          </div>
          <div :class="navIndex==='4'?'li active':'li'" v-if="systemConfig.platformFor==='school'"
               @click="selectNav('4')"
          >课程列表
          </div>
          <div :class="navIndex==='3'?'li active':'li'" @click="selectNav('3')">实训详情</div>
        </div>
      </div>
      <!--右侧-->
      <div class="right flex flex-start">
        <div class="avatar">
          <i class="el-icon-user-solid avatar" :style="userInfo.hasOwnProperty('userId')?'color:#4093f9':''"></i>
        </div>
        <template v-if="userInfo.hasOwnProperty('userId')">
          <div class="realName cursor-pointer" @click="clickUserAvatar">{{ userInfo.name }}</div>
          <div class="tools flex flex-start">
            <span class="exitBtn cursor-pointer" @click="clickLoginOutBtn">退出</span>
          </div>
        </template>
        <template v-if="!userInfo.hasOwnProperty('userId')">
          <div class="realName cursor-pointer"></div>
          <div class="tools flex flex-start">
<!--            <span class="cursor-pointer realName" @click="clickLoginBtn">登录</span>-->
          </div>
        </template>

      </div>
    </div>
    <!--回到顶部按钮-->
    <div class="back-top" style="display: none;cursor: pointer" @click="clickBackTopBtn">
      <img src="@/assets/icons/tools-backTop.svg" alt="回到顶部">
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {UserModel} from "../../model/UserModel";
import store from "../../store";
import $ from "jquery"
import {getQuery} from "../../utils/common";

export default {
  name: "pageHeader",
  computed: {
    ...mapState({
      webConfig: state => state.webConfig,
      isLoginPage: state => state.isLoginPage,
      userInfo: state => state.userInfo,
      systemConfig: state => state.systemConfig,
      introduceConfig: state => state.introduceConfig,
      navCollegeList: state => state.navCollegeList,
      courseId: state => state.courseId,
      courseInfo: state => state.courseInfo,
      loginFrom: state => state.loginFrom,
    })
  },
  data() {
    return {
      navIndex: '3',
    }
  },
  mounted() {
    if (window.location.href.indexOf("/introduce") > -1) {
      let desIndex = getQuery("desIndex")
      this.$set(this, "navIndex", "2-" + desIndex)
    }
    if (window.location.href.indexOf("/introduce") === -1) {
      if (window.location.href.indexOf("/courseList") === -1) {
        this.$set(this, "navIndex", "3")
      }
    }
  },
  async created() {
    // 该页会最先加载，所以在此获取系统各项配置

    // 检测系统配置是否已加载 获取系统配置
    if (!store.state.webConfig.hasOwnProperty("logoUrl")) {
      await store.dispatch("getWebConfig")
      store.dispatch("getSystemConfig").then((systemConfig) => {

      })
      await store.dispatch("getIntroduceConfig")
    }
  },
  methods: {
    // 选择了某个导航
    selectNav(navIndex) {
      if (navIndex.indexOf("2-") === 0) { // 介绍
        let introduceId = navIndex.replace("2-", "")
        window.location.href = "/introduce?courseId=" + this.courseId + "&desIndex=" + introduceId
      }
      if (navIndex === "3") {
        window.location.href = "/" + this.courseInfo.coursePath
      }
      if (navIndex === "4") {
        window.location.href = "/CourseList"
      }
    },
    // 点击回到顶部按钮
    clickBackTopBtn() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    // 点击退出登录按钮
    clickLoginOutBtn() {
      UserModel.loginOut()
    },
    // 点击用户头像
    clickUserAvatar() {
      this.$router.push({name: "UserInfo"})
    },
    gotoUrl(url) {
      if (this.$route.path !== url) {
        this.$router.push(url)
      }
    },
    // 点击登录按钮
    clickLoginBtn() {
      this.$router.push("/login")
    }
  }
}
</script>
<style scoped lang="less">
@import '../../style/app.less';

.page-header {
  width: 100%;
  background-color: #fff;
  z-index: 1999;


  .left {
    .web-title {
      .logo {
        width: 50px;
        height: 50px;
        margin-right: 10px;
      }

      span.title {
        font-size: 16px;
        color: #555;
      }
    }

    .menu-list {
      > .li {
        display: inline-block;
        font-size: 16px;
        color: #000;
        opacity: 0.6;
        text-decoration: none;
        text-align: center;
        height: 72px;
        min-width: 120px;
        line-height: 72px;
        padding: 0px 20px;
        overflow: hidden;
        cursor: pointer;
      }

      > .li.active, > .li:hover {
        font-weight: bold;
        opacity: 1 !important;

        border-bottom: 3px @main-color solid;
      }
    }
  }

  .right {
    .realName {
      margin-right: 15px;
      color: @main-color;
      font-size: 14px;
    }

    .tools {
      .exitBtn {
        color: #888;
        font-size: 14px;
      }
    }

    div.avatar {
      margin-right: 5px;

      i.avatar {
        color: @remark-color;
        font-size: 22px;
      }
    }
  }
}

.back-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 99999;

  img {
    width: 40px;
    height: 40px;
  }
}
</style>
