<template>
  <div class="page-footer">
    <div class="info-container content-container">
      <div class="infos">
        <p>{{courseInfo.schoolName}}</p>
        <span><a href="http://www.cdzyhd.com" target="_blank">技术支持:成都智云鸿道信息技术有限公司</a></span>
        <span>推荐使用:谷歌浏览器、火狐浏览器</span>
        <span><a href="https://beian.miit.gov.cn/">{{webConfig.beiAnText}}</a></span>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "pageFooter",
  computed: {
    ...mapState({
      webConfig: state => state.webConfig,
      courseInfo: state => state.courseInfo,
    })
  },
}
</script>

<style scoped lang="less">
@import '../../style/app.less';

.page-footer {
  background-color: #2d2d2d;
  padding: 20px 0px;

  .info-container {
    .logo{
      width: 70px;
      height: 70px;
      cursor: pointer;
      display: none;
    }
    .infos {
      text-align: center;
      color: @remark-color;
      font-size: 14px;

      span {
        margin-right: 10px;
        a{
          color: #888;
          text-decoration: none;
        }
      }
    }
  }
}
</style>
